
import logo from "../../assets/image/logo.png"
import { onLogin } from '../../api/login'
import { Button, ConfigProvider, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom'
import { setToken } from '../../store/storage'
type FieldType = {
    username?: string;
    password?: string;
};

export const LoginPage = () => {
    const navigate = useNavigate()
    const onFinish = async (values: any) => {
        const { data } = await onLogin(values)
        setToken(data)
        navigate('/')
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };



    return (
        <div className="login_content">
            <div className="login_logo">
                <img src={logo} alt="" />
            </div>

            <div className="login_form">
                <div className="login_form_main">
                    <h1 className="login_form_title">欢迎访问，邦尼招生管理后台</h1>
                        
                    <Form
                        name="login"
                        layout='vertical'
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item<FieldType>
                            required={false}
                            label="用户名: "
                            name="username"
                            rules={[{ required: true, message: '请输入用户名！' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item<FieldType>
                            required={false}
                            label="密码:"
                            name="password"
                            rules={[{ required: true, message: '请输入密码！' }]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Button: {
                                        defaultBg: '#181A1B'
                                    },
                                },
                            }}
                        >
                            <Form.Item>
                                <Button block={true} style={{ marginTop: '48px', color: '#fff' }} htmlType="submit">
                                    登录
                                </Button>
                            </Form.Item>
                        </ConfigProvider> 
                    </Form>

                    <div className="login_form_tootip">忘记密码？请联系软件服务商重置</div>
                </div>
            </div>
        </div>
    )
}
