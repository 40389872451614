
import { useState, useEffect } from 'react'
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { BuiltModule } from './built'
import { ResetModule } from './reset'
import { ChangeDateModule } from './change-date'
import { SchoolListType } from '../../../type'
import { onGetSchoolList } from '../../../api/school'



export const SchoolPage = () => {
    const [data, setData] = useState<SchoolListType[]>([])
    const columns: ColumnsType<SchoolListType> = [
        {
            title: '学校名称',
            dataIndex: 'school_name',
        },
        {
            title: '院校代码',
            dataIndex: 'uniid',
        },
        {
            title: '登录用户名',
            dataIndex: 'username',
        },
        {
            title: '开户时间',
            dataIndex: 'create_time',
        },
        {
            title: '上线时间',
            dataIndex: 'online_time',
        },
        {
            title: '到期时间',
            dataIndex: 'due_time',
        },
        {
            title: '操作',
            render: (_, record) => (
                <div className='school_handle'>
                    <BuiltModule 
                        title='编辑学校信息' 
                        getData={getData}
                        id={record.school_id}>
                        <div className='school_btn'>
                            编辑
                        </div>
                    </BuiltModule>
                    <ResetModule id={record.school_id}></ResetModule>

                    <ChangeDateModule id={record.school_id} getData={getData}></ChangeDateModule>
                </div>
            ),
        },
    ];
   
    const getData = async () => {
        const {data} = await onGetSchoolList()
        // console.log(data);
        
        setData(data)
        
    }
    useEffect(() => {
        getData()
    }, []);
    return (
        <div className="common_content">
            <div className="common_header">
                <h4>开户学校列表</h4>
                <BuiltModule title='新增学校' getData={getData}>
                    <button className="common_btn">添加新学校</button>
                </BuiltModule>
            </div>
            <div className='school_main'>
                <Table columns={columns} dataSource={data} rowKey='school_id' />
            </div>
        </div>
    )
}
