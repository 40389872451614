
import { useState, useEffect, useRef } from 'react'
import { Select, DatePicker, Table } from 'antd';
import type { DatePickerProps } from 'antd/es/date-picker';
import type { ColumnsType } from 'antd/es/table';
import { onGetChatList, onExportData } from '../../../../api/chat'
import { ChatParamsType } from '../../../../type'

type DataType = {
    id: number
    answer_category: string,
    answer_content: string
    question: string
    school_name: string
    update_time: string
    user_id: string
}

const columns: ColumnsType<DataType> = [
    {
        title: '咨询问题',
        render: (_, record: any) => (
            <>
                <div className='note_td'>
                    <span>Q</span>:&nbsp; 
                    {record.question}
                </div>
                <div className='note_td'>
                    <span>A</span>:&nbsp; 
                    {record.answer_content}
                </div>
            </>
        )
    },
    {
        title: '答案类别',
        render: (_, record: any) => (
            <div dangerouslySetInnerHTML={{ __html: record.answer_category }}></div>
        ),
        width: 128
    },
    {
        title: '咨询用户id',
        dataIndex: 'user_id',
        width: 128
    },
    {
        title: '所属学校',
        dataIndex: 'school_name',
        width: 168
    },
    {
        title: '咨询时间',
        dataIndex: 'update_time',
        width: 128
    }
];

export const NotePage = () => {
    const [params, setParams] = useState<ChatParamsType>({
        category: null,
        start_time: "",
        end_time: "",
        page: 1,
        per_page: 20
    })
    const format = "YYYY-MM-DD HH:mm"
    const [isRest, setIsRest] = useState<number>(0)
    const [total, setTotal] = useState<number>(0)
    const [data, setData] = useState<DataType[]>([])
    const timeRef = useRef<any>(null)

    const handleChange = (value: string, type: string) => {
        console.log(`selected ${value}`);
        setParams({
            ...params,
            [type]: value
        })
    };
    const onChange = (
        value: DatePickerProps['value'],
        dateString: [string, string] | string,
        type: string
    ) => {
        setParams({
            ...params,
            [type]: dateString
        })
    };

    const onPagination = ({ current }: any) => getData(current)

    const getData = async (page?: number) => {
        const { data } = await onGetChatList({
            ...params,
            page: page || 1
        })
        setData(data.chat_records)
        setTotal(data.total)
        setParams({
            ...params,
            page: data.current_page
        })
    }

    const onFilter = () => {
        const { category, start_time, end_time } = params
        if (category) {
            getData()
        }

        if (start_time && end_time) {
            getData()
        }
    }
    
    const onExport = async () => {
        const { data } = await onExportData({})
        let link = document.createElement('a');
        link.href = data;
        link.download = '招生计划.xlsx';
        link.click();
    }
    
    const onRest = () => {
        setParams({
            category: null,
            start_time: "",
            end_time: "",
            page: 1,
            per_page: 20
        })
        if (timeRef.current) {
            clearTimeout(timeRef.current)
        }
        timeRef.current = setTimeout(() => {
            setIsRest(Math.random())
        }, 200)
        
    }
    useEffect(() => {
        getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRest]);
    return (
        <>
            <div className="common_content">
                <h4>智能问答聊天数据</h4>

                <div className='note_header'>
                    <div>
                        <span>结果类别：</span>
                        <Select
                            key={isRest}
                            style={{ width: 128 }}
                            placeholder="选择结果类别"
                            onChange={(value: any) => { handleChange(value, 'category') }}
                            allowClear
                            options={[
                                { value: 1, label: '未命中' },
                                { value: 2, label: '闲聊' },
                                { value: 3, label: '命中答案' },
                            ]}
                        />
                    </div>
                    <div>
                        <span>开始时间：</span>
                        <DatePicker
                            showTime
                            key={isRest}
                            format={format}
                            placeholder='年/月/日 时:分'
                            onChange={(_, value) => {
                                onChange(_, value, 'start_time')
                            }}
                            
                        />
                    </div>
                    <div>
                        <span>结束时间：</span>
                        <DatePicker
                            showTime
                            key={isRest}
                            format={format}
                            placeholder='年/月/日 时:分'
                            onChange={(_, value) => {
                                onChange(_, value, 'end_time')
                            }}
                        />
                    </div>

                    <button className='common_btn' onClick={onFilter}>筛选</button>
                    <button className='common_btn' onClick={onRest}>重置</button>
                    <button className='note_export' onClick={onExport}>导出</button>
                </div>

                <div className='school_main'>
                    <Table columns={columns} 
                        dataSource={data}
                        pagination={{
                            current: params.page,
                            pageSize: params.per_page,
                            total: total,
                            hideOnSinglePage: true,
                            showSizeChanger: false
                        }}
                        scroll={{
                            y: `calc(100vh - 248px)`
                        }}
                        onChange={onPagination} 
                        rowKey="id" />
                </div>
            </div>           

           
        </>
    )
}
