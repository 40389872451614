

import { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import logo from "../../assets/image/logo.png"
import { BookOutlined } from '@ant-design/icons';
import { Modal } from 'antd'
import { removeToken, setNavKey, getNavKey, removeNavKey } from '../../store/storage'
export const LayoutPage = () => {
    
    const navigate = useNavigate()

    const navKey = getNavKey()
    const [isActive, setIsActive] = useState<string>(navKey || '1')

    const [isModalOpen, setIsModalOpen] = useState(false);

    const sidebarList = [{
        title: '招生小程序',
        children: [{
            id: '1',
            title: '开通学校',
            url: '/',
            icon: <BookOutlined />,
        }]
    }, {
        title: '智能问答',
        children: [{
            id: '2',
            title: '问答记录',
            url: '/note',
            icon: <BookOutlined />,
        }, {
            id: '3',
            title: '常见问题',
            url: '/problem',
            icon: <BookOutlined />,
        }]
    }, {
        title: '我的账号',
        children: [{
            id: '4',
            title: '退出登录',
            url: '',
            icon: <BookOutlined />,
        }]
    }]

    const handleOk = () => {
        removeToken()
        navigate('/login')
        removeNavKey()
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onChange = (id: string, url?: string) => {
        if(url) {
            setIsActive(id)
            setNavKey(id)
            navigate(url)
        } else {
            setIsModalOpen(true);
        }
    }

    return (
        <>
            <div className="layout_content">
                <div className="layout_left">
                    
                    <div className="layout_left_header">
                        <h4>
                            邦尼招生系统管理后台
                        </h4>
                        <div className="layout_left_header_logo">
                            <img src={logo} alt="" />
                        </div>
                    </div>
                   
                    <ul className='layout_sidebar'>
                        {
                            sidebarList.map((item, index) => {
                                return <li className='layout_sidebar_item' key={index}>
                                    <div className='layout_sidebar_item_title'>{item.title}</div>
                                    {
                                        item.children.map(child => {
                                            return <div className=
                                                {`
                                                    layout_sidebar_item_subtitle 
                                                    ${child.id === isActive ? 'layout_sidebar_item_active' : ''}
                                                `} key={child.id}
                                                onClick={() => onChange(child.id, child.url)}
                                               >
                                                {child.icon}
                                                <span>{child.title}</span>
                                            </div>
                                        })
                                    }
                                   
                                </li>
                            })
                        }
                    </ul>
                </div>
                <div className='layout_main'>
                    <Outlet></Outlet>
                </div>
            </div>
            <Modal title="退出登录" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div>确认退出登录吗?</div>
            </Modal>
        </>
    )
}
