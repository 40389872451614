

import { useState } from 'react'
import { Modal, DatePicker, message } from 'antd';
import type { DatePickerProps } from 'antd/es/date-picker';
import { onEditSchoolTime } from '../../../api/school'

export const ChangeDateModule = ({ id, getData }: any) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [date, setDate] = useState<{
        online_time: string,
        due_time: string,
    }>({
        online_time: '',
        due_time: ''
    })
    const format = "YYYY-MM-DD HH:mm"

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setDate({
            online_time: '',
            due_time: ''
        })
    };

    const onChange = (
        value: DatePickerProps['value'],
        dateString: [string, string] | string,
        type: string
    ) => {
        setDate({
            ...date,
            [type]: dateString
        })
    };

    const handleOk = async () => {
        if (date.online_time && date.due_time) {
            const { errcode }: any = await onEditSchoolTime({
                school_id: id,
                ...date
            })
            if (errcode === 0) {
                message.success('修改成功')
                getData()
                handleCancel()
                
            }
        } else {
            message.error('时间不能为空')
        }
       
    }
    return (
        <>
            <div onClick={showModal} className='school_btn'>
                修改服务时间 
            </div>
            <Modal title="编辑学校服务时间"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}>
                <div className='school_data_item'>
                    <div>上线时间：</div>
                    <DatePicker 
                        showTime
                        format={format}
                        placeholder='年/月/日 时:分' 
                        onChange={(_, value) => {
                            onChange(_, value, 'online_time')
                        }}
                    />
                </div>
                <div className='school_data_item'>
                    <div>到期时间：</div>
                    <DatePicker 
                        showTime 
                        format="YYYY-MM-DD hh:mm" 
                        placeholder='年/月/日 时:分'
                        onChange={(_, value) => {
                            onChange(_, value, 'due_time')
                        }}
                    />
                </div>
            </Modal>
        </>
    )
}
