import request from '../utils/http'
import { ChatParamsType } from '../type'

export const onGetChatList = (data: ChatParamsType) => {
    return request({
        url: '/a/chat/list/get',
        method: 'post',
        data
    })
}

export const onExportData = (data?: any) => {
    return request({
        url: '/a/chat/export',
        method: 'post',
        data
    })
}

export const onGetChatFagList = (data: any) => {
    return request({
        url: '/a/faq/list/get',
        method: 'post',
        data
    })
}
export const onAddChatFag = (data: any) => {
    return request({
        url: '/a/faq/add',
        method: 'post',
        data
    })
}

export const onEditChatFag = (data: any) => {
    return request({
        url: '/a/faq/edit',
        method: 'post',
        data
    })
}

export const onDeleteChatFag = (id: number) => {
    return request({
        url: '/a/faq/delete',
        method: 'post',
        data: {
            faq_id: id
        }
    })
}
