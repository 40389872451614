
import request from '../utils/http'
type DataType = {
    username: string,
    password: string | number
}
export const onLogin = (data: DataType) => {
    return request({
        url: '/a/login',
        method: 'post',
        data
    })
}
