

import { useState, useEffect} from 'react'
import { Table, Popconfirm, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { BuiltModule } from './built';
import { onGetChatFagList, onDeleteChatFag } from '../../../../api/chat'

interface ProblemListType {
    id: number
    question: string
    school_name: string
}

export const ProblemPage = () => {
    const [data, setData] = useState<ProblemListType[]>([])
    
    const columns: ColumnsType<ProblemListType> = [
        {
            title: '常见问题标题',
            dataIndex: 'question',
        },
      
        {
            title: '所属学校',
            dataIndex: 'school_name',
        },
        {
            title: '操作',
            render: (_, record) => (
                <div className='school_handle'>
                    <BuiltModule title="编辑问题" item={record} getData={getData}>
                        <div className='school_btn'>
                            编辑
                        </div>
                    </BuiltModule>
                    <Popconfirm
                        title="删除"
                        description="确定要删除该问题吗?"
                        onConfirm={() => { confirm(record.id) }}
                        okText="是"
                        cancelText="否"
                    >
                        <div className='school_btn'>
                            删除
                        </div>
                    </Popconfirm>


                </div>
            ),
        },
    ]; 
    const [total, setTotal] = useState<number>(0)
    const [params, setParams] = useState<{
        page: number,
        per_page: number,
        school_uniid: number
    }>({
        page: 1,
        per_page: 20,
        school_uniid: 90026
    })
    const confirm = async (id: number) => {
        const {errcode}: any = await onDeleteChatFag(id)
        if(errcode === 0) {
            message.success('删除成功')
            getData()
        } 
    }

    const onPagination = ({ current }: any) => getData(current)

    const getData = async (page?: number) => {
        const {data} = await onGetChatFagList({
            ...params,
            page: page || 1,
        })
        setData(data.faqs)
        setTotal(data.total)
        setParams({
            ...params,
            page: data.current_page
        })
    }
    
    useEffect(() => {
        getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="common_content">
            <div className="common_header">
                <h4>开户学校列表</h4>
                <BuiltModule title="添加新问题" getData={getData}>
                    <button className="common_btn">添加新问题</button>
                </BuiltModule>
            </div>
            {/* <div className='problem_query'>
                <span>学校名称：</span>
                <Select
                    style={{ width: 200 }}
                    placeholder="选择学校"
                    onChange={handleChange}
                    allowClear
                    options={[
                        { value: 'jack', label: '海军军医大学' },
                        { value: 'lucy', label: 'Lucy' },
                        { value: 'Yiminghe', label: 'yiminghe' },
                        { value: 'disabled', label: 'Disabled', disabled: true },
                    ]}
                />
            </div> */}
            <div className='school_main'>
                <Table columns={columns}
                    dataSource={data}
                    pagination={{
                        current: params.page,
                        pageSize: params.per_page,
                        total: total,
                        hideOnSinglePage: true,
                        showSizeChanger: false
                    }}
                    scroll={{
                        y: `calc(100vh - 248px)`
                    }}
                    onChange={onPagination}
                    rowKey="id" />
            </div>
        </div>
    )
}


