
import request from '../utils/http'
import { SchoolType } from '../type'

export const onGetSchoolList = () => {
    return request({
        url: '/a/school/list/get',
        method: 'post'
    })
}

export const onAddSchool = (data: SchoolType) => {
    return request({
        url: '/a/school/add',
        method: 'post',
        data
    })
}

export const onGetSchoolDetail = (id: number) => {
    return request({
        url: '/a/school/detail/get',
        method: 'post',
        data: {
            school_id: id
        }
    })
}

export const onEditSchool = (data: SchoolType) => {
    return request({
        url: '/a/school/info/edit',
        method: 'post',
        data
    })
}

export const onEditSchoolPwd = (data: { school_id: number, password: string}) => {
    return request({
        url: '/a/school/pwd/edit',
        method: 'post',
        data
    })
}

export const onEditSchoolTime = (data: { school_id: number, online_time: string, due_time: string }) => {
    return request({
        url: '/a/school/time/edit',
        method: 'post',
        data
    })
}
