import { createBrowserRouter, redirect } from "react-router-dom";
import { LoginPage } from "../page/login";
import { LayoutPage } from '../page/layout'
import { SchoolPage } from "../page/layout/school";
import { NotePage } from "../page/layout/inter/note";
import { ProblemPage } from "../page/layout/inter/problem";
import { getToken } from '../store/storage'
const onRedirect = () => {
    const isToken = getToken()
    if (!isToken) {
        throw redirect('/login')
    }
    return true
}

const notNavigate = () => {
    const isToken = getToken()
    if (isToken) {
        throw redirect('/')
    }
    return true
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <LayoutPage />,
        loader: onRedirect,
        children: [{
            index: true,
            element: <SchoolPage></SchoolPage>
        }, {
            path: 'note',
            element: <NotePage></NotePage>
        }, {
            path: 'problem',
            element: <ProblemPage></ProblemPage>
        }]
    },
    {
        path: 'login',
        loader: notNavigate,
        element: <LoginPage></LoginPage>
    }
])

export default router
