
import { useState } from 'react'
import { Modal, Button, ConfigProvider, Form, Input, message } from 'antd';
import {onEditSchoolPwd} from '../../../api/school'

type FieldType = {
    password?: string;
    confirm?: string
};

export const ResetModule = ({ id }: any) => {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields()
    };

    const onFinish = async (values: any) => {

        const { errcode }: any = await onEditSchoolPwd({
            school_id: id,
            password: values.password
        })
        if (errcode === 0) {
            message.success('修改密码成功')
            handleCancel()
        }
    };

    return (
        <>
            <div onClick={showModal} className='school_btn'>
                重置密码
            </div>
            <Modal title="重置密码"
                open={isModalOpen}
                footer={false}
                onCancel={handleCancel}>
                <Form
                    form={form}
                    name="built"
                    layout='vertical'
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item<FieldType>
                        required={false}
                        label="登录密码:"
                        name="password"
                        rules={[{ required: true, message: '必填!' }]}
                    >
                        <Input placeholder='请输入新的登录密码' />
                    </Form.Item>

                    <Form.Item<FieldType>
                        required={false}
                        label="确认登录密码:"
                        validateTrigger="onBlur"
                        name="confirm"
                        rules={[{ required: true, message: '必填!' }, ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('两次密码输入不一致'));
                            },
                        })]}
                    >
                        <Input placeholder='请再次输入新的登录密码' />
                    </Form.Item>

 

                    <ConfigProvider
                        theme={{
                            components: {
                                Button: {
                                    defaultBg: '#181A1B'
                                },
                            },
                        }}
                    >
                        <Form.Item>
                            <Button block={true} style={{ marginTop: '24px', color: '#fff' }} htmlType="submit">
                                提交
                            </Button>
                        </Form.Item>
                    </ConfigProvider>
                </Form>
            </Modal>
        </>
    )
}
