
import { useState } from 'react'
import { Modal, Input } from 'antd';
import { onAddChatFag, onEditChatFag } from '../../../../api/chat';
import { message } from 'antd/lib';

const { TextArea } = Input;

interface PropsType {
    children: any 
    title: string
    getData: () => void
    item?: {
        id: number,
        question: string
    }
}

export const BuiltModule = (props: PropsType) => {

    const {
        children,
        title,
        getData,
        item
    } = props
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [question, setQuestion] = useState<string>('')
    const showModal = () => {
        setIsModalOpen(true);
        if(item?.id) {
            setQuestion(item.question)
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setQuestion('')
    };
    const onFinish = async () => {
        if (!question) return message.error('请输入内容')
        if (!item?.id) {
            const { errcode }: any = await onAddChatFag({
                school_uniid: 90026,
                question
            })
            if (errcode === 0) {
                message.success("添加成功")
                getData()
                handleCancel()
            }
        } else {
            const { errcode }: any = await onEditChatFag({
                school_uniid: 90026,
                faq_id: item.id,
                question
            })
            if (errcode === 0) {
                message.success("编辑成功")
                getData()
                handleCancel()
            }
        }
    };
  
    return (
        <>
            <div onClick={showModal}>
                {children}
            </div>
            <Modal title={title}
                open={isModalOpen}
                onOk={onFinish}
                onCancel={handleCancel}>

                <TextArea style={{
                    margin: '12px 0'
                }} rows={6} value={question} onChange={(e) => { setQuestion(e.target.value)}} placeholder='请输入...' />

                {/* <Select placeholder="请选择学校">
                    <Select.Option value="demo">Demo</Select.Option>
                </Select> */}
            </Modal>
        </>
    )
}
