
import { useState } from 'react'
import { Modal, Button, ConfigProvider, Form, Input, message } from 'antd';
import {BuiltType} from '../../../type'
import { onAddSchool, onEditSchool, onGetSchoolDetail } from '../../../api/school'
type FieldType = {
    school_name: string
    uniid: number | null
    username: string
    password?: string
    appid?: string
    secret?: string
    confirm?: string
};

export const BuiltModule = (props: BuiltType) => {
    const [form] = Form.useForm();
    const {
        children,
        title,
        id,
        getData
    } = props
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState<FieldType>({
        school_name: '',
        uniid: null,
        username: '',
        appid: '',
        secret: '',
    })
    const showModal = async () => {
        if(id) {
            const {data} = await onGetSchoolDetail(id)
            setInitialValues(data)
        }

        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields()
    };

    const onFinish = async (values: any) => {
        if(!id) {
            const { errcode }: any = await onAddSchool({
               ...values,
               uniid: parseInt(values.uniid)
            })
            if (errcode === 0) {
                message.success('添加成功')
                getData()
                handleCancel()
                
            };
        } else {
            const { errcode }: any = await onEditSchool({
                ...values,
                school_id: id,
                uniid: parseInt(values.uniid)
            })
            if (errcode === 0) {
                message.success('编辑成功')
                getData()
                handleCancel()
            };
        }
    };

    return (
        <>  
            <div onClick={showModal}>
                { children }
            </div>
            <Modal title={title}
                open={isModalOpen} 
                footer={false}
                onCancel={handleCancel}>
                <Form
                    form={form}
                    name={id ? 'edit' : 'add'}
                    layout='vertical'
                    initialValues={initialValues}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item<FieldType>
                        required={false}
                        label="学校名称:"
                        name="school_name"
                        rules={[{ required: true, message: '必填!' }]}
                    >
                        <Input placeholder='建议使用学校全称避免重复' />
                    </Form.Item>

                    <Form.Item<FieldType>
                        required={false}
                        label="院校代码:"
                        name="uniid"
                        rules={[{ required: true, message: '必填!' }]}
                    >
                        <Input placeholder='院校代码或其他信息作为唯一标识符 (11位以内纯数字)' />
                    </Form.Item>

                    <Form.Item<FieldType>
                        required={false}
                        label="登录用户名:"
                        name="username"
                        rules={[{ required: true, message: '必填!' }]}
                    >
                        <Input placeholder='登录学校管理后台的用户名' />
                    </Form.Item>
                    {
                        !id ? <>
                            <Form.Item<FieldType>
                                required={false}
                                label="登录密码:"
                                name="password"
                                rules={[{ required: true, message: '必填!' }]}
                            >
                                <Input placeholder='登录学校管理后台的密码' />
                            </Form.Item>

                            <Form.Item<FieldType>
                                required={false}
                                validateTrigger="onBlur"
                                label="确认登录密码:"
                                name="confirm"
                                rules={[{ required: true, message: '必填!' }, ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('两次密码输入不一致'));
                                    },
                                })]}
                            >
                                <Input placeholder='再次输入登录密码' />
                            </Form.Item>
                        </> : <></>
                    }
                    

                    <Form.Item<FieldType>
                        required={false}
                        label="小程序appid:"
                        name="appid"
                    >
                        <Input placeholder='微信小程序的appid (小程序用户登录需要)' />
                    </Form.Item>

                    <Form.Item<FieldType>
                        required={false}
                        label="小程序secret:"
                        name="secret"
                    >
                        <Input placeholder='微信小程序的secret (小程序用户登录需要)' />
                    </Form.Item>

                    <ConfigProvider
                        theme={{
                            components: {
                                Button: {
                                    defaultBg: '#181A1B'
                                },
                            },
                        }}
                    >
                        <Form.Item>
                            <Button block={true} style={{ marginTop: '24px', color: '#fff' }} htmlType="submit">
                                提交
                            </Button>
                        </Form.Item>
                    </ConfigProvider>
                </Form>
            </Modal>
        </>
    )
}
