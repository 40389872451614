

import axios from 'axios'
import { getToken, removeToken } from '../store/storage'
import { message } from 'antd'
axios.defaults.baseURL = process.env.REACT_APP_BASE_API

axios.interceptors.request.use(
    config => {
        const token = getToken()
        if (token) {
            config.headers['Authorization'] = token
        } 
        return config
    },

    error => {
       
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    response => {
        const data = response.data
        if (response.status === 200) {
            return data
        } else {
            message.error(data.msg)
            return Promise.reject(new Error(data.msg))
        }
    },
    
    error => {
        if (error && error.response && error.response.status === 401) {
            removeToken()
            window.location.href = '/login'
            message.error('身份凭证失效, 请重新登录')
        }
        if (error && error.response && error.response.status === 500) {
            message.error('系统繁忙,请稍后重试')
        }

    }
)

export default axios
